import React, { Component } from 'react'
import './App.css';


import {
    BrowserRouter as Router,
    Switch,
    Route,

} from "react-router-dom";
import Menu from './components/Menu';
import Dashboard from './views/Dashboard';
import Stat from './views/Stat';
import GraphSum from './views/Graph-sum';
import GraphIntro from './views/Graph-intro';
import Deposit from './views/Deposit';
import Withdraw from './views/Withdraw';
import Bank from './views/ฺBank';
import TrueWallet from './views/Truewallet';
import Staff from './views/Staff';
import MemberRegister from './views/Member-register';
import MemberReport from './views/Member-report';
import MemberCheckdeposit from './views/Member-check-deposit';
import MemberCheckdot from './views/Member-check-dot';
import MemberCheckwallet from './views/Member-check-wallet';
import Affiliate from './views/Affiliate';
import MarketingCoupon from './views/Marketing-coupon';
import MarketingCard from './views/Marketing-card';
import MarketingWheel from './views/Marketing-wheel';
import MarketingLinkRef from './views/Marketing-linkref';
import Profile from './views/Profile';
import ActivityLog from './views/Activitylog';
import SummarySum from './views/Summary-sum';
import SummaryPromotion from './views/Summary-promotion';
import SummaryHidden from './views/Summary-deposithidden';
import Faq from './views/Faq';
import HtEbook from './views/Ht-ebook';
import HtVideo from './views/Ht-Video';
import HtDot from './views/Ht-dot';
import HtAuthen from './views/Ht-authen';
import HtLine from './views/Ht-line';
import HtMenu from './views/Ht-menu';
import HtClose from './views/Ht-close';
import HtSetbank from './views/Ht-setbank';
import { connect } from 'react-redux'


const mapStateToProps = state => {
    return {

        contentColor: state.contentColor,
        fontColor: state.fontColor
    }
}

const App = ({ contentColor }) => (

    <Router>

        <Menu />
        <Switch>

            <Route exact path="/">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>

                    <Dashboard />

                </div>

            </Route>
            <Route path="/dashboard-stat">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Stat />
                </div>
            </Route>
            <Route path="/graph-intro">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <GraphIntro />
                </div>
            </Route>
            <Route path="/graph-sum">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <GraphSum />
                </div>
            </Route>
            <Route path="/deposit">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Deposit />
                </div>
            </Route>
            <Route path="/withdraw">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Withdraw />
                </div>
            </Route>
            <Route path="/bank">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Bank />
                </div>
            </Route>
            <Route path="/truewallet">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <TrueWallet />
                </div>
            </Route>
            <Route path="/staff">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Staff />
                </div>
            </Route>
            <Route path="/member-register">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MemberRegister />
                </div>
            </Route>
            <Route path="/member-report">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MemberReport />
                </div>
            </Route>
            <Route path="/member-checkdeposit">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MemberCheckdeposit />
                </div>
            </Route>
            <Route path="/member-checkdot">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MemberCheckdot />
                </div>
            </Route>
            <Route path="/member-checkwallet">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MemberCheckwallet />
                </div>
            </Route>
            <Route path="/affiliate">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Affiliate />
                </div>
            </Route>
            <Route path="/marketing-coupon">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MarketingCoupon />
                </div>
            </Route>
            <Route path="/marketing-card">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MarketingCard />
                </div>
            </Route>
            <Route path="/marketing-wheel">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MarketingWheel />
                </div>
            </Route>
            <Route path="/marketing-linkref">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <MarketingLinkRef />
                </div>
            </Route>
            <Route path="/profile">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Profile />
                </div>
            </Route>
            <Route path="/activity-log">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <ActivityLog />
                </div>
            </Route>
            <Route path="/summary-sum">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <SummarySum />
                </div>
            </Route>
            <Route path="/summary-promotion">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <SummaryPromotion />
                </div>
            </Route>
            <Route path="/summary-deposithidden">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <SummaryHidden />
                </div>
            </Route>
            <Route path="/faq">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <Faq />
                </div>
            </Route>
            <Route path="/ht-ebook">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtEbook />
                </div>
            </Route>
            <Route path="/ht-video">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtVideo />
                </div>
            </Route>
            <Route path="/ht-setbank">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtSetbank />
                </div>
            </Route>
            <Route path="/ht-setdot">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtDot />
                </div>
            </Route>
            <Route path="/ht-authen">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtAuthen />
                </div>
            </Route>
            <Route path="/ht-line">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtLine />
                </div>
            </Route>
            <Route path="/ht-menu">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtMenu />
                </div>
            </Route>
            <Route path="/ht-close">
                <div className="content-wrapper" style={{backgroundColor: contentColor }}>
                    <HtClose />
                </div>
            </Route>
        </Switch>

    </Router>


);



export default connect(mapStateToProps)(App);