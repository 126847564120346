import themeTypes from './themeTypes'

export function light(){
    return {type: themeTypes.LIGHT}
}
export function dark(){
    return {type: themeTypes.DARK}
}
export function rb(){
    return {type: themeTypes.RB}
}
