import React,{Component} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {light ,dark,rb} from '../redux/theme/themeAction'
class Menu extends Component  {
    render(){
    return (
        (
            <div style={{ position: 'sticky', top: 0, left: 0 }}>
    
                <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{backgroundColor:(this.props.navColor)}}>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a style={{color:this.props.fontColor}} className="nav-link"  data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                        </li>
                        <li className="nav-item">
                            <a style={{color:this.props.fontColor}} className="nav-link" onClick={() => {this.props.light()}}    role="button"><i class="fas fa-sun"></i></a>
                        </li>
                        <li className="nav-item">
                            <a style={{color:this.props.fontColor}} className="nav-link" onClick={() =>{this.props.dark()}}    role="button"><i class="fas fa-moon"></i></a>
                        </li>
                        <li className="nav-item">
                            <a style={{color:this.props.fontColor}} className="nav-link"  onClick={() =>{this.props.rb()}}   role="button"><i class="fas fa-eye"></i></a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
    
                        <li className="nav-item">
                            <div className="user-panel mt-1 pb-1 mb-1 d-flex">
                                <button type="button" className="btn btn-danger"><i className="nav-icon fas fa-sign-out-alt" />ออกจากระบบ</button>
                            </div>
                        </li>
                    </ul>
                </nav>
    
                <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ height: "100vh" ,backgroundColor:(this.props.asideColor)}}>
                    {/* Brand Logo */}
                    <Link to="/" className="brand-link">
                        <img src="../dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span style={{color:this.props.fontColor}} className="brand-text font-weight-light">AdminLTE 3</span>
                    </Link>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* SidebarSearch Form */}
                        {/* Sidebar Menu */}
                        <nav className="mt-2 mb-3">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class
                   with font-awesome or any other icon font library */}
                                <li style={{color:this.props.fontColor}} className="nav-header pt-1">รายการ</li>
                                <li className="nav-item menu-close">
                                    
                                    
                                        <li className="nav-item">
                                            <Link to="/" className="nav-link ">
                                                {/* <a href="../dashboard" className="nav-link"> */}
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-tachometer-alt" />
                                                <p style={{color:this.props.fontColor}}>Dashboard</p>
                                            </Link>
                                            {/* </a> */}
                                        </li>
                                        
                                    
                                </li>
                                
                                    
                                    
                                        <li className="nav-item">
                                            <Link to="/graph-intro" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-chart-pie" />
                                                <p style={{color:this.props.fontColor}}>ตั้งค่าทั่วไป</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/graph-intro" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-chart-pie" />
                                                <p style={{color:this.props.fontColor}}>ตั้งค่าแบรนด์</p>
                                            </Link>
                                        </li>
                                    
                                
                                <li className="nav-item pt-2">
                                    <Link to="/deposit" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-money-bill-alt" />
                                        <p style={{color:this.props.fontColor}}>
                                            Token Line
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/withdraw" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-money-bill" />
                                        <p style={{color:this.props.fontColor}}>
                                            ทรูวอลเล็ต
                        </p>
                                    </Link>
                                </li>
                                
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-users" />
                                        <p style={{color:this.props.fontColor}}>
                                            จัดการสมาชิก
                          <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/member-register" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-user" />
                                                <p style={{color:this.props.fontColor}}>ยอดสมัครสมาชิก</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/member-report" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="far fa-user nav-icon" />
                                                <p style={{color:this.props.fontColor}}>เช็คข้อมูลสมาชิก</p>
                                            </Link>
                                        </li>
                                        
                                    </ul>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/staff" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-user-tie" />
                                        <p style={{color:this.props.fontColor}}>
                                            พนักงาน
                        </p>
                                    </Link>
                                </li>
                                
                                <li className="nav-item pt-2">
                                    <Link to="/affiliate" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-user-graduate" />
                                        <p style={{color:this.props.fontColor}}>
                                            โปรโมชั่น
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/affiliate" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-user-graduate" />
                                        <p style={{color:this.props.fontColor}}>
                                            ป็อปอัพโปรโมชั่น
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-chart-area" />
                                        <p style={{color:this.props.fontColor}}>
                                            รายการฝากถอน
                          <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/marketing-coupon" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-money-bill-wave-alt" />
                                                <p style={{color:this.props.fontColor}}>ฝากถอนเงินสมาชิก</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-card" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-chess-queen nav-icon" />
                                                <p style={{color:this.props.fontColor}}>รายงานฝากเงิน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-wheel" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-ring nav-icon" />
                                                <p style={{color:this.props.fontColor}}>รายงานถอนเงิน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-linkref" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-link nav-icon" />
                                                <p style={{color:this.props.fontColor}}>จัดการถอนเงิน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-linkref" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-link nav-icon" />
                                                <p style={{color:this.props.fontColor}}>รายการเติมเงินผิดพลาด</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/profile" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-portrait" />
                                        <p style={{color:this.props.fontColor}}>
                                            Affiliate
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/profile" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-portrait" />
                                        <p style={{color:this.props.fontColor}}>
                                            ยอดคืนเงิน
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/profile" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-portrait" />
                                        <p style={{color:this.props.fontColor}}>
                                            ตั้งค่าเกม
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-chart-area" />
                                        <p style={{color:this.props.fontColor}}>
                                            การตลาด
                          <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/marketing-coupon" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-money-bill-wave-alt" />
                                                <p style={{color:this.props.fontColor}}>คูปอง</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-card" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-chess-queen nav-icon" />
                                                <p style={{color:this.props.fontColor}}>เปิดไพ่</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-wheel" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-ring nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วงล้อ</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/marketing-linkref" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-link nav-icon" />
                                                <p style={{color:this.props.fontColor}}>Link reference</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                
                                <li className="nav-item pt-2">
                                    <Link to="/activity-log" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-newspaper" />
                                        <p style={{color:this.props.fontColor}}>
                                            Activity Log
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-file-invoice" />
                                        <p style={{color:this.props.fontColor}}>
                                            สรุปรายงาน
                          <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/summary-sum" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-chart-line" />
                                                <p style={{color:this.props.fontColor}}>กำไร / ขาดทุน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/summary-promotion" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-percent nav-icon" />
                                                <p style={{color:this.props.fontColor}}>รายงานสรุปต่อ User</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/summary-promotion" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-percent nav-icon" />
                                                <p style={{color:this.props.fontColor}}>โปรโมชั่น</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/summary-deposithidden" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-file-invoice-dollar nav-icon" />
                                                <p style={{color:this.props.fontColor}}>รายงานสรุป Affiliate</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item pt-2">
                                    <Link to="/faq" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-columns" />
                                        <p style={{color:this.props.fontColor}}>
                                            คำถามที่พบบ่อย FAQ
                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link ">
                                        <i style={{color:this.props.fontColor}} className="nav-icon fas fa-book" />
                                        <p style={{color:this.props.fontColor}}>
                                            คู่มือการใช้งาน
                          <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/ht-ebook" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-book" />
                                                <p style={{color:this.props.fontColor}}>E-Book</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-video" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fab fa-youtube nav-icon" />
                                                <p style={{color:this.props.fontColor}}>Video</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-setbank" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-book nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วิธีเซ็ตธนาคาร</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-setdot" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-book nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วิธีเซ็ตฝากแบบทศนิยม</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-authen" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-book nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วิธีเซ็ตสร้างรหัสยืนยันตัวตน</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-line" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-book nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วิธีเซ็ตLine Notify</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-menu" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="nav-icon fas fa-book " />
                                                <p style={{color:this.props.fontColor}}>ตั้งค่าสิทธิ์เมนู</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ht-close" className="nav-link ">
                                                <i style={{color:this.props.fontColor}} className="fas fa-book nav-icon" />
                                                <p style={{color:this.props.fontColor}}>วิธีปิดระบบ</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul></nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        )
    )
    }    
}
const mapStateToProps = state => {
    return{
        navColor:state.navColor,
        asideColor:state.asideColor,
        fontColor:state.fontColor
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        light: () => dispatch(light()),
        dark: () => dispatch(dark()),
        rb: () => dispatch(rb())
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (Menu)
