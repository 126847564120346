import React,{useEffect} from 'react'
import {connect} from 'react-redux'
const Htebook = ({fontColor}) => (
  useEffect(() => {
      window.scrollTo(0, 0)
    }, []),
  
      <div  >
      <h1 style={{color:fontColor}}>
          E-book
          </h1>
  
  </div>
  
)
const mapStateToProps = state => {
  return{
      fontColor:state.fontColor
  }
}

export default connect(mapStateToProps) (Htebook)
