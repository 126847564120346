import themeTypes from './themeTypes'

const initialState = {
    navColor:'#FFF',
    contentColor:'#f8f9fa',
    asideColor:'#343a40',
    fontColor:'#C2C7D0'

}

function themeReducer(state = initialState,action){
    switch(action.type){
        case themeTypes.LIGHT:
            return{
                ...state,
                navColor:'#FFF',
                contentColor:'#f8f9fa',
                asideColor:'#FFF',
                fontColor:'#000000'
            };
        case themeTypes.DARK:
            return{
                ...state,
                navColor:'#343a40',
                contentColor:'#343a40',
                asideColor:'#343a40',
                fontColor:'#C2C7D0'
            };
        case themeTypes.RB:
            return{
                ...state,
                navColor:'#fff0b3',
                contentColor:'#fff0b3',
                asideColor:'#fff0b3',
                fontColor:'#000000'
            };
        default:
            return state;            
    }
}

export default themeReducer;