import React,{useEffect} from 'react'
import {connect} from 'react-redux'
const GraphIntro = ({fontColor}) => (
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []),
    
        <div >
        <h1 style={{color:fontColor}}>ข้อมูลเบื้องต้น</h1>
    
    </div>
    
)
const mapStateToProps = state => {
    return{
        fontColor:state.fontColor
    }
}
export default connect(mapStateToProps) (GraphIntro)